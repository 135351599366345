import { getEntity } from "./schemaProvider";
import { getLabelFields } from "./labeller";
import { buildSearchFilter } from "./searchFilterBuilder";
import { and } from "@/features/schemas/services/schemaFiltering";
import { hasProperty } from "@/services/objectUtility";

export function buildTableQuery(entityKey, searchText, options) {
    const queryFields = getEntity(entityKey).queryFields;
    // Filter out fields that are duplicate foreign key fields.
    let fields = Object
        .values(queryFields)
        .filter((field) => !hasProperty(queryFields, `${field.key}Id`));

    let filter = buildFilter(fields, entityKey, searchText, options);
    let model = {
        filter,
        sortBy: fields[0].key,
        skip: options?.skip,
        take: options?.take
    };
    if (options?.sortBy != null) {
        model.sortBy = options.sortBy;
        model.direction = options.direction;
    }

    return model;
}

export function buildSelectQuery(entityKey, searchText, options) {
    let fields = getLabelFields(entityKey);

    let filter = buildFilter(fields, entityKey, searchText, options);
    let model = {
        filter,
        sortBy: fields[0].key,
        take: 100
    };
    if (options?.sortBy != null) {
        model.sortBy = options.sortBy;
        model.direction = options.direction;
    }
    return model;
}

function buildFilter(fields, entityKey, searchText, options) {
    fields = fields.filter(e => !hasProperty(e, "notSearchable"));

    if (!fields.length) {
        throw `Unable to search ${entityKey} as there are no suitable fields available.`;
    }
    let filter = buildSearchFilter(fields, searchText);
    if (options?.filter) {
        filter = filter ?
            and([filter, options.filter]) :
            options.filter;
    }
    return filter;
}

export default {
    buildTableQuery,
    buildSelectQuery
}

