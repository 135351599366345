<template>
    <app-dialog :value="!!value" @keydown.esc="$emit('input', null)">
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="save()">
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-spacer />
                    <v-toolbar-title>Onboard School</v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
                <div style="margin: 15px 15px 0px 15px">
                    <app-text-field
                        v-model="schoolName"
                        label="School Name"
                        :rules="schoolNameRules" />
                    <app-text-field
                        v-model="schoolAdminName"
                        label="School Admin Name"
                        :rules="schoolAdminNameRules" />
                    <app-text-field
                        v-model="numberOfTeachers"
                        label="Number of Teachers (minimum 3)"
                        :rules="numberOfTeachersRules" />
                    <app-text-field
                        v-model="schoolAdminEmail"
                        label="School Administrator's Email"
                        :rules="emailRules" />
                    <v-radio-group
                        v-model="paymentMethod"
                        class="mt-1"
                        :rules="paymentMethodRules">
                        <v-radio label="Direct Debit" value="directDebit" />
                        <v-radio label="Purchase Order" value="purchaseOrder" />
                    </v-radio-group>
                </div>
                <v-divider />

                <v-card-actions class="mx-1 py-4">
                    <v-spacer />
                    <app-button
                        color="secondary"
                        outlined
                        :disabled="processing"
                        @click="cancel">
                        Cancel
                    </app-button>
                    <app-button
                        :disabled="processing"
                        type="submit">
                        Save
                    </app-button>
                </v-card-actions>
            </v-card>
        </v-form>
    </app-dialog>
</template>

<script>
import { onboardSchool } from "../services/onboardSchoolApiService";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            valid: true,
            schoolName: "",
            numberOfTeachers: 3,
            schoolAdminName: null,
            schoolAdminEmail: "",
            schoolNameRules: [
                v => !!v || "School Name is required",
                v =>
                    !(v && v.length > 128) ||
                    "School Name must be less than 128 characters",
            ],
            schoolAdminNameRules: [
                v => !!v || "School Admin Name is required",
                v =>
                    !(v && v.length > 128) ||
                    "School Admin Name must be less than 128 characters",
            ],
            numberOfTeachersRules: [
                v => v >= 3 || "You need to purchase at least three teacher subscriptions"
            ],
            emailRules: [v => !!v || "Email is required"],
            paymentMethod: "",
            paymentMethodRules: [v => !!v || "Payment method is required"],
            processing: false
        };
    },
    methods: {
        cancel() {
            this.$emit('input', null);
        },
        async save() {
            this.$refs.form.validate();
            if (!this.valid) {
                return;
            }
            this.processing = true;
            try {
                await onboardSchool(
                    this.schoolName,
                    this.numberOfTeachers,
                    this.schoolAdminName,
                    this.schoolAdminEmail,
                    this.paymentMethod
                );
            }
            finally {
                this.processing = false;
                this.$emit('input', null);
            }
        }
    }
}
</script>
