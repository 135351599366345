<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
import { format } from "@/formatting/percentageFormatter";

export default {
    types: [
        "percentage"
    ],
    props: {
        value: {
            type: [ Number ],
            default: null,
        }
    },
    computed: {
        display() {
            return format(this.value);
        }
    }
};
</script>
