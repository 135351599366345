import {
    buildCellOptions,
    getCellComponent,
    getHeaders,
    getTableData,
    applyForeignKeySorting,
    updateLookups,
    getLookupTypes
} from "@/features/schemas/services/tableService";

import { equal, and } from "@/features/schemas/services/schemaFiltering";
import schemaMixin from "./schemaMixin";
import { hasProperty } from '@/services/objectUtility';
import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
import { buildTableQuery } from "@/features/schemas/services/searcher";
import { debounce } from "@/services/debounce";
import { subscribe } from "@/services/actionHub";

export default {
    mixins: [ schemaMixin ],
    props: {
        filter: {
            type: Object,
            default: null
        },
        fixedValues: {
            type: Object,
            default: null,
        },
        searchText: {
            type: String,
            default: "",
        },
        dynamicDisabled: {
            type: Boolean,
            default: false
        },
        sortBy: {
            type: String,
            default: null
        },
        direction: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            entityHeaders: [],
            options: {},
            tableData: {
                items: [],
                total: 0,
                lookups: {}
            },
            hasExtraActions: false,
            subscription: null,
            loading: false
        };
    },
    computed: {
        headers() {
            if (!(this.canEdit || this.canView || this.hasExtraActions)) {
                return this.entityHeaders;
            }

            return this.entityHeaders.concat({
                text: "",
                value: "actions",
                sortable: false,
                class: "action-cell"
            });
        },
        fieldKeys() {
            return this.entityHeaders.map(h => h.value);
        },
        fixedFields() {
            return Object.keys(this.allFixedValues ?? {});
        },
        // This is so we can override the fixed values, as you can't override a prop.
        localFixedValues() {
            return {};
        },
        allFixedValues() {
            return { ...this.localFixedValues, ...this.fixedValues };
        },
        requestModel() {
            const fixedFilters = this
                .fixedFields
                .map(f => equal(this.entityKey, f, this.allFixedValues[f]));

            const filters = [...fixedFilters, this.filter].filter(f => f != null);
            const filter = filters.length ? and(filters) : null;

            let options = this.options;
            if (options) {
                options.sortBy = applyForeignKeySorting(this.entityKey, options.sortBy);
            }
            if (!options?.sortBy && this.sortBy) {
                options = options ?? {};
                options.sortBy = this.sortBy;
                options.direction = this.direction;
            }
            return { filter, ...options };
        }
    },
    watch: {
        entityKey: {
            immediate: true,
            handler(value) {
                this.entityHeaders = value ?
                    getHeaders(value).filter(h => !this.fixedFields.includes(h.value)) :
                    [];
            }
        },
        requestModel: {
            immediate: true,
            async handler() {
                await this.refresh();
            }
        },
        searchText: {
            immediate: false,
            async handler() {
                if (isNullOrWhiteSpace(trim(this.searchText, '"'))) {
                    await this.refresh();
                    return;
                }
                this.debouncedSearch();
            },
        }
    },
    mounted() {
        this.hasExtraActions = hasProperty(this.$scopedSlots, "extra-actions");
        let entityKeys = [
            this.entityKey,
            ...getLookupTypes(this.entityKey)
        ];
        this.subscription = subscribe(this.receiveMessages, entityKeys);
    },
    destroyed() {
        this.subscription.unsubscribe();
    },
    methods: {
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.tableData, fieldKey);
        },
        async refresh() {
            if (!this.options?.take && !this.dynamicDisabled) {
                return;
            }

            if (isNullOrWhiteSpace(trim(this.searchText, '"'))) {
                this.loading = true;
                try {
                    this.tableData = await getTableData(this.entityKey, this.requestModel, true);
                    return;
                }
                finally {
                    this.loading = false;
                }
            }

            await this.search();
        },
        viewItem(item) {
            if (!this.canView) {
                return;
            }
            this.$router.push({
                name: this.entityKey,
                params: { itemId: item.id }
            });
        },
        async receiveMessages(messages) {
            // If this entity has changes, it's simpliest to refresh the data with the current
            // filter. This is because a change may impact filtering, sorting and pagination.
            if(messages.some(m => m.entityKey == this.entityKey)) {
                await this.refresh();
                return;
            }

            // If there are changes to my lookup types, then determine if any lookup data should be
            // refreshed.
            await updateLookups(this.tableData.lookups, messages);
        },
        async search() {
            let searchText = this.searchText;

            let model = buildTableQuery(this.entityKey, searchText, this.requestModel);
            this.loading = true;
            try {
                let results = await getTableData(this.entityKey, model, true);

                // Avoid showing previous search if results arrive out of order.
                if (searchText === this.searchText) {
                    this.tableData = results;
                }
            }
            finally {
                this.loading = false;
            }
        },
    },
    created() {
        this.debouncedSearch = debounce(() => this.search(), 250);
    },
}
