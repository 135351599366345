import Vue from "vue";
import vuetify from "./vuetify";
import App from "./App.vue";
import exporter from "./features/schemas/services/exporter";
import router from "./routes";
import setupInterceptor from './services/interceptor'
import store from "./store/store";
import { handleError } from "./services/errorUtility";

import "./components";
import "./features/schemas/formFields"
import "./features/schemas/tableCells"
import "./mixins/mixins";
import "./features/schemas/components";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
Vue.config.errorHandler = handleError;

setupInterceptor();

new Vue({
    vuetify,
    router,
    store,
    exporter,
    render: h => h(App),
}).$mount("#app");
