import { handleError } from "@/services/errorUtility";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"

const subscriptions = [];

export async function connectToActionHub() {
    const connection = new HubConnectionBuilder()
        .withUrl("/hubs/action")
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    connection.on("Receive", onReceive);
    await connection.start();
    return connection;
}

export async function closeActionHubConnection(connection) {
    connection.off("Receive");
    connection.stop();
}

export function subscribe(handler, entityKeys) {
    let subscription = {
        handler,
        entityKeys: new Set(entityKeys)
    };
    subscription.unsubscribe = () => unsubscribe(subscription);
    subscriptions.push(subscription);
    return subscription;
}

function unsubscribe(subscription) {
    const index = subscriptions.indexOf(subscription);
    if (index > -1) {
        subscriptions.splice(index, 1);
    }
}

function onReceive(messages) {
    for(let i = 0; i < subscriptions.length; i++) {
        let subscription = subscriptions[i];
        // Include a subset of the messages based on the entities that have been subscribed to.
        let filteredMessages = messages.filter(m => subscription.entityKeys.has(m.entityKey));
        if(!filteredMessages.length) {
            continue;
        }

        try {
            subscription.handler(filteredMessages);
        }
        catch(e) {
            handleError(e);
        }
    }
}