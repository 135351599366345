<template>
    <div class="d-flex flex-column">
        <div class="d-flex">
            <app-button
                v-for="(item, i) in items"
                :key="i"
                class="mr-4"
                :outlined="item !== selectedItem"
                @click="selectedItem = item">
                {{ getTitle(item) }}
            </app-button>
        </div>

        <component
            :is="tableComponent"
            v-if="selectedItem"
            :key="selectedItem.entity.key"
            class="flex-grow-1 mt-3"
            :entity-key="selectedItem.entity.key"
            :fixed-values="getFixedValues(selectedItem)" />
    </div>
</template>


<script>
import { getDependants } from "@/features/schemas/services/schemaProvider";
import { getEntity } from "@/features/schemas/services/schemaProvider"
import tables from "@/features/schemas/tables";
import schemaMixin from '@/features/schemas/mixins/schemaMixin'

export default {
    mixins: [schemaMixin],

    props: {
        itemId: {
            type: [Number, String],
            default: null
        }
    },

    data() {
        return {
            selectedItem: null
        }
    },

    computed: {
        items() {
            return getDependants(this.entityKey);
        },
        entity() {
            return getEntity(this.entityKey);
        },
        tableComponent() {
            if (!this.selectedItem) {
                return null;
            }
            return tables.getComponent(this.selectedItem.entity.key);
        },
    },
    watch: {
        items: {
            immediate: true,
            handler() {
                if (!this.items.length) {
                    return;
                }
                this.selectedItem = this.items[0];
            }
        }
    },
    methods: {
        getTitle(item) {
            // If the foreign key title matches this entity's title, then the foreign key entity
            // title will suffice.
            if(item.field.title === this.entity.singleTitle) {
                return item.entity.pluralTitle;
            }

            // Otherwise, include the field title to distinguish the source of the records.
            return `${item.entity.pluralTitle} (${item.field.title})`;
        },
        getFixedValues(item) {
            let result = {};
            result[item.field.key] = this.itemId;
            return result;

            // return equal(item.field.key, parseInt(this.itemId), "int");
        }
    }
}
</script>

<style scoped>
.schema-table {
    margin-top: 0px;
}
</style>
