/**
 * Maintains a promise value that can be awaited until the owner completes.
**/
export function createCompletion() {
    let _resolve;
    const createPromise = () => new Promise((resolve) => {
        _resolve = resolve;
    });
    let promise = createPromise();
    const result = () => promise;
    result.complete = () => _resolve();
    result.reset = () => {
        promise = createPromise();
    };
    return result;
}
