<template>
    <v-avatar 
        :class="[ backgroundClass, 'app-avatar', sizeClass ]" 
        :size="small ? 25 : 40">
        {{ initials }}
    </v-avatar>
</template>

<script>
import styles from '@/services/styles'
import { mapGetters } from 'vuex';

export default {
    props: {
        personId: {
            type: Number,
            default: null,
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            getPerson: "data/getPerson"
        }),
        person() {
            return this.getPerson(this.personId);
        },
        initials() {
            let name = this.person?.name;
            if(name == null) {
                return;
            }
            let nameParts = name.split(" ");
            let initials = nameParts.map(n => n[0]);
            return initials.join("").toUpperCase();
        },
        backgroundClass() {
            return styles.getBackgroundColorClass(this.personId);
        },
        sizeClass() {
            return this.small ? "app-avatar--small" : null;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/style/theme.scss";

    .app-avatar{ 
        @include shadow;
        color: #fff; 
        font-size: 19px;
    }

    .app-avatar--small {
        font-size: 12px;
    }
</style>
