import { Duration } from "luxon"

export const types = [
    "timespan"
]

export function format(value) {
    if (value == null) { 
        return "";
    }
    
    // TODO: determine if there's any localisation we should be doing here.
    return Duration
        .fromISO(value)
        .toHuman();
}
