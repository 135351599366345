export const types = [
    "currency"
];

export function format(value) {
    if (!value) {
        return "$0.00";
    }
    return value.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'symbol'
    });
}
