<template>
    <v-card>
        <v-card-text>
            TODO
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import localValueMixin from "@/mixins/localValueMixin";
import schemaMixin from "@/features/schemas/mixins/schemaMixin";

export default {
    mixins: [ localValueMixin, schemaMixin ],
    props: {
        value: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            filter: {}
        };
    },
    computed: {
        ...mapGetters({
            getEnum: "schema/getEnum"
        })
    }
}
</script>