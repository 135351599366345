let nextId = 0;

function build(type, message) {
    return { 
        id: nextId++,
        type, 
        message
    }
}

function addThenRemoveOnTimeout(commit, type, message) {
    let item = build(type, message);
    commit("add", item);
    setTimeout(() => commit('remove', item), 7000);
}

const state = {
    items: []
}

const actions = {
    success({ commit }, message) {
        addThenRemoveOnTimeout(commit, "success", message);
    },
    info({ commit }, message) {
        addThenRemoveOnTimeout(commit, "info", message);
    },
    warning({ commit }, message) {
        addThenRemoveOnTimeout(commit, "warning", message);
    },
    error({ commit }, message) {
        addThenRemoveOnTimeout(commit, "error", message);
    },
    clear({ commit }) {
        commit("clear");
    },
    remove({ commit }, item) {
        commit("remove", item);
    }
};

const mutations = {
    add(state, item) {
        state.items.push(item);
    },
    clear(state) {
        state.items = [];
    },
    remove(state, item) {
        let index = state.items.indexOf(item);
        if(index >= 0) {
            state.items.splice(index, 1);
        }
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
}