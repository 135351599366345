<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
import { camelToTitleCase } from "@/services/stringUtility"

export default {
    types: [
        "enum"
    ],
    props: {
        value: {
            type: [ Number, String ],
            default: null,
        }
    },
    computed: {
        display(){
            // TODO: Consider we may want to render enums which are integers.
            return camelToTitleCase(this.value);
        }
    }
};
</script>