let appTableView = () =>
    import(
        /* webpackChunkName: "schemaTableView" */ "@/features/schemas/views/SchemaTableView.vue");

export default [
    {
        path: "/action-logs",
        name: "actionLogs",
        staticProps: { entityKey: "actionLog" },
    },
    {
        path: "/daily-review-variants",
        name: "dailyReviewVariants",
        staticProps: { entityKey: "dailyReviewVariant" },
    },
    {
        path: "/feedbacks",
        name: "feedbacks",
        staticProps: { entityKey: "feedback" },
    },
    {
        path: "/files",
        name: "files",
        staticProps: { entityKey: "file" },
    },
    {
        path: "/formats",
        name: "formats",
        staticProps: { entityKey: "format" },
    },
    {
        path: "/free-access-domains",
        name: "freeAccessDomains",
        staticProps: { entityKey: "freeAccessDomain" },
    },
    {
        path: "/people",
        name: "people",
        staticProps: { entityKey: "person" },
    },
    {
        path: "/schools",
        name: "schools",
        staticProps: { entityKey: "school" },
    },
    {
        path: "/school-teachers",
        name: "schoolTeachers",
        staticProps: { entityKey: "schoolTeacher" },
    },
    {
        path: "/settings",
        name: "settings",
        staticProps: { entityKey: "setting" },
    },
    {
        path: "/slides",
        name: "slides",
        staticProps: { entityKey: "slide" },
    },
    {
        path: "/subscriptions",
        name: "subscriptions",
        staticProps: { entityKey: "subscription" },
    },
    {
        path: "/tags",
        name: "tags",
        staticProps: { entityKey: "tag" },
    },
    {
        path: "/tag-categories",
        name: "tagCategories",
        staticProps: { entityKey: "tagCategory" },
    },
    {
        path: "/topic-tags",
        name: "topicTags",
        staticProps: { entityKey: "topicTag" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { entityKey: "user" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { entityKey: "userRole" },
    },
    {
        path: "/variants",
        name: "variants",
        staticProps: { entityKey: "variant" },
    },
].map(e => {
    return { ...e, component: appTableView };
});
