import { getLocale } from "@/services/localisation";

export const types = [
    "percentage"
];

export function format(value) {
    if (!value) {
        return "0%"
    }
    return (value * 100).toLocaleString(getLocale(), { maximumFractionDigits: 2 }) + "%"
}
