<template>
    <div>
        <v-card v-if="item" outlined rounded="sm">
            <v-card-text>
                <div class="d-flex">
                    <h1 class="mt-2">
                        {{ entity.singleTitle }}
                    </h1>
                    <v-spacer />
                    <app-icon-button
                        icon="mdi-pencil"
                        @click="edit" />
                </div>
                <div v-for="field in fields" :key="field.key" class="mb-2">
                    <label>
                        {{ field.title }}
                    </label>

                    <component
                        :is="getCellComponent(field.key)"
                        :key="field.key"
                        :options="getCellOptions(field.key)"
                        :value="item[field.key]"
                        :item="item" />
                </div>
            </v-card-text>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem"
            @input="onItemSaved" />
    </div>
</template>

<script>
import {
    getCellComponent,
    buildCellOptions,
    getTableData,
    getLookupTypes,
    updateLookups
} from "@/features/schemas/services/tableService";

import { equal } from "@/services/filtering";
import schemaMixin from '@/features/schemas/mixins/schemaMixin'
import { getTableRoute } from '@/features/schemas/services/schemaRouteProvider'
import { subscribe } from "@/services/actionHub";
import { goTo } from "@/services/routeService";

export default {
    mixins: [schemaMixin],

    props: {
        itemId: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            tableData: null,
            isDeleted: false,
            editedItem: null
        };
    },

    computed: {
        fields() {
            return Object.values(this.entity.queryFields);
        },
        item() {
            const items = this.tableData?.items;
            if(!items?.length) {
                return null;
            }
            return items[0];
        }
    },

    watch: {
        itemId: {
            immediate: true,
            async handler() {
                await this.refresh();
            }
        }
    },
    mounted() {
        let entityKeys = [
            this.entityKey,
            ...getLookupTypes(this.entityKey)
        ];
        this.subscription = subscribe(this.receiveMessages, entityKeys);
    },
    destroyed() {
        this.subscription.unsubscribe();
    },
    methods: {
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.tableData, fieldKey);
        },
        async refresh() {
            if(!this.itemId) {
                this.tableData = null
                return;
            }

            const filter = equal("id", this.itemId, "int");
            this.tableData = await getTableData(this.entityKey, { filter }, true);
            if(this.item == null) {
                // Go to table route. This can happen if the item is deleted while viewing it.
                let route = getTableRoute(this.entityKey) ?? "/";
                goTo(route);
            }
        },
        async receiveMessages(messages) {
            const thisItemHasUpdates = messages
                .some(m =>
                    m.entityKey == this.entityKey &&
                    m.id == this.itemId);

            if(thisItemHasUpdates) {
                await this.refresh();
                return;
            }

            await updateLookups(this.tableData.lookups, messages);
        },
        edit() {
            this.editedItem = this.item;
        },
        async onItemSaved() {
            this.editedItem = null;
        },
    }
}
</script>

<style scoped>
label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    font-weight: 700;
}
h1 {
    font-size: 32px;
    font-weight: 300;
}
</style>
