import { getLocale } from "@/services/localisation";

export default {
    camelToKebabCase,
    camelToTitleCase,
    pascalToCamelCase,
    isNullOrWhiteSpace,
    containsNonNumericCharacters,
    trim,
    naturalCompare
};

export function camelToKebabCase(value) {
    if (value === null || value === "") {
        return value;
    }

    return value.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        (substring, offset) => (offset ? "-" : "") + substring.toLowerCase());
}

export function camelToTitleCase(value) {
    if (value === null || value === "") {
        return value;
    }

    return value
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        .replace(/^./, s => s.toUpperCase());
}

export function pascalToCamelCase(value) {
    if (value === null || value === "") {
        return value;
    }
    return value.charAt(0).toLowerCase() + value.slice(1);
}

export function camelToPascalCase(value) {
    if (value === null || value === "") {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isNullOrWhiteSpace(value) {
    return value == null || (typeof value === 'string' && value.trim() == "");
}

export function containsNonNumericCharacters(value) {
    return !!value.match(/[^$,.\d]/);
}

export function trim(value, character) {
    if (value === null || value === "") {
        return value;
    }
    let start = 0;
    let end = value.length;

    while(start < end && value[start] === character) {
        ++start;
    }

    while(end > start && value[end - 1] === character) {
        --end;
    }

    if(start === 0 && end === value.length) {
        return value;
    }

    return value.substring(start, end);
}

export function trimSuffix(value, suffix) {
    if(!suffix?.length || !value.endsWith(suffix)) {
        return value;
    }
    return value.slice(0, value.length - suffix.length);
}

export function trimPrefix(value, prefix) {
    if(!prefix?.length || !value.startsWith(prefix)) {
        return value;

    }
    return value.slice(value.length - prefix.length - 1);
}

export function naturalCompare(a, b) {
    const tokenize = (s) => {
        const tokens = [];
        let buffer = '';
        let isDigit = false;

        for (const char of s) {
            if (/\d/.test(char)) {
                if (!isDigit) {
                    if (buffer) {
                        tokens.push(buffer);
                    }
                    buffer = char;
                    isDigit = true;
                } else {
                    buffer += char;
                }
            } else {
                if (isDigit) {
                    if (buffer) {
                        tokens.push(parseInt(buffer, 10));
                    }
                    buffer = char;
                    isDigit = false;
                } else {
                    buffer += char;
                }
            }
        }

        if (buffer) {
            tokens.push(isDigit ? parseInt(buffer, 10) : buffer);
        }

        return tokens;
    };

    const tokensA = tokenize(a);
    const tokensB = tokenize(b);

    const length = Math.min(tokensA.length, tokensB.length);

    for (let i = 0; i < length; i++) {
        const itemA = tokensA[i];
        const itemB = tokensB[i];

        if (typeof itemA === 'number' && typeof itemB === 'number') {
            if (itemA !== itemB) {
                return itemA - itemB;
            }
        } else if (typeof itemA === 'string' && typeof itemB === 'string') {
            const comparison = itemA.localeCompare(itemB, getLocale());
            if (comparison !== 0) {
                return comparison;
            }
        } else {
            // Number always comes before string
            return typeof itemA === 'number' ? -1 : 1;
        }
    }

    // If one string is a prefix of the other, the shorter string comes first
    return tokensA.length - tokensB.length;
}
