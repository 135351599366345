<template>
    <v-text-field
        v-model="localeDate"
        v-bind="$attrs"
        class="app-date-field"
        dense
        outlined
        :placeholder="formatString"
        :rules="combinedRules">
        <template #append>
            <app-date-picker v-model="valueLocal" />
        </template>
    </v-text-field>
</template>

<script>
import { isNullOrWhiteSpace } from "@/services/stringUtility"

import {
    dateTimesEqual,
    formatDate,
    getDateFormatString,
    parseDate,
    parseIso
} from "@/services/dateUtility"

function toIso(localeDate) {
    return parseDate(localeDate)?.toISO()
}

function mapRule(rule) {
    return localeDate => rule(toIso(localeDate));
}

function parseDateOrNull(localeDate) {
    let dateTime = parseDate(localeDate);

    if (dateTime === null || !dateTime.isValid) {
        return null;
    }

    return dateTime;
}

// Consider the following represents a full date with an offset:
// 2019-09-28T00:00:00+08:00

export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            // This is in the short date format for the configured locale.
            localeDate: null,
            defaultRules: [
                v => (!this.required || !isNullOrWhiteSpace(v)) || "Date is required",
                v => {
                    if (isNullOrWhiteSpace(v)) {
                        return true;
                    }

                    let date = parseDate(v);

                    if(!date.isValid){
                        return "Enter date as " + getDateFormatString().toLowerCase();
                    }
                    if(date.year >= 10000) {
                        return "Ensure year is less than 10000";
                    }

                    return true;
                }
            ]
        };
    },

    computed: {
        combinedRules() {
            return [  ...this.defaultRules, ...this.rules.map(mapRule), ];
        },
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        valid() {
            return this
                .combinedRules
                .every(rule => rule(this.localeDate) === true);
        },
        formatString() {
            return getDateFormatString()
                // making this lower case is not correct, but looks nicer when displayed.
                .toLowerCase();
        }
    },

    watch: {
        localeDate(localeDate) {
            if (isNullOrWhiteSpace(localeDate)) {
                this.$emit("input", null);
                return;
            }

            if(!this.valid) {
                return;
            }

            let isoDate = toIso(localeDate);

            if (this.value !== isoDate) {
                this.$emit("input", isoDate);
            }
        },
        value: {
            immediate: true,
            handler(value) {
                let currentDateTime = parseDateOrNull(this.localeDate)
                let dateTime = parseIso(value);

                if(!dateTimesEqual(dateTime, currentDateTime)) {
                    this.localeDate = formatDate(dateTime);
                }
            }
        }
    }
};

</script>

<style lang="scss">
    .app-date-field {
        &.v-text-field--enclosed.v-input--dense.v-text-field--outlined .v-input__append-inner {
            margin: 2px -10px 2px 2px;
        }
    }
</style>
