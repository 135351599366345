<template>
    <v-dialog
        v-bind="$attrs"
        :value="value"
        max-width="500px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :persistent="!terminable"
        v-on="$listeners">
        <slot />
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        terminable: {
            type: Boolean,
            default: false
        }
    }
}
</script>
