let report = () =>
    import(/* webpackChunkName: "report" */ "@/features/reports/views/Report.vue");
export default [
    // {
    //     path: "/time-report",
    //     name: "timeReport",
    //     staticProps: { entityKey: "timeReport" },
    // }
].map(e => {
    return { ...e, component: report };
});
