<template>
    <v-dialog v-model="dialog" :max-width="maxWidth">
        <v-card>
            <v-card-title class="headline">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <slot />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="showCancel"
                    color="grey darken-1"
                    text
                    @click="onCancel">
                    {{ cancelText }}
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="onOk">
                    {{ okText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'MessageDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        showCancel: {
            type: Boolean,
            default: false
        },
        okText: {
            type: String,
            default: 'OK'
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        maxWidth: {
            type: [String, Number],
            default: 500
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        onOk() {
            this.$emit('ok');
            this.dialog = false;
        },
        onCancel() {
            this.$emit('cancel');
            this.dialog = false;
        }
    }
}
</script>
