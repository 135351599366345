<template>
    <div>
        {{ value }}
    </div>
</template>

<script>
export default {
    types: [
        "string",
        "int",
        "decimal",
        "default"
    ],
    props: {
        value: {
            type: [String, Number, Boolean],
            default: null,
        }
    }
};
</script>