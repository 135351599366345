import store from "@/store/store";
import { isNullOrWhiteSpace } from "./stringUtility";

export function getErrorMessage(error) {
    const validationMessage = isValidationError(error) ? getValidationErrorMessage(error) : null;
    const { response } = error;
    const { data } = response || {};
    return validationMessage ?? data?.message ?? data ?? error.toString();
}

function getErrorMessages(error) {
    const showMultiple = shouldShowMultipleErrors(error);
    const messages = showMultiple
        ? Object.entries(error.response.data.errors)
            .map(([, msgs]) => msgs)
            .flat()
        : [getErrorMessage(error)];
    return messages.filter((msg) => !isNullOrWhiteSpace(msg));
}

function getValidationErrorMessage(error) {
    return error.response?.data?.message.split("\n")[0] || "";
}

export function handleError(error) {
    if (isDevelopment()) {
        // eslint-disable-next-line no-console
        console.log(error);
    }

    if (!shouldShowErrors(error)) return;

    const messages = getErrorMessages(error);
    messages.forEach(showError);
}

function shouldShowErrors(error) {
    if (isDevelopment()) return true;

    const { status } = error.response || {};
    return status >= 400 && status < 500;
}

function shouldShowMultipleErrors(error) {
    return isDevelopment() && !!error.response?.data?.errors;
}

function isDevelopment() {
    return process.env.NODE_ENV === "development";
}


function isValidationError(error) {
    return !isNullOrWhiteSpace(error.response?.data?.message);
}

function showError(message) {
    store.dispatch("alert/error", message);
}


export default {
    getErrorMessage,
    handleError,
};
