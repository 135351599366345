<template>
    <div>
        <v-text-field
            v-model="duration"
            v-bind="$attrs"
            autocomplete="off"
            class="app-duration-field"
            dense
            outlined
            placeholder="0:00"
            :rules="combinedRules"
            @focus="$event.target.select()"
            @change="setDuration" />
    </div>
</template>

<script>
import { Duration } from "luxon"
import { isNullOrWhiteSpace } from '@/services/stringUtility';
import { formatDuration, parseDuration } from '@/services/durationUtility'

// Format should be h:mm:ss, h:mm, or a number
function isValid(input) {
    let match = input.match(/(\d+:\d+:\d+)|(\d+:\d+)|(\d+\.?\d*)/g);
    return match !== null && match[0] === input;
}

function mapRule(rule) {
    return duration => rule(parseDuration(duration).toMillis());
}

export default {
    props: {
        value: {
            type: Number,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            duration: null,
            defaultRules: [
                v => (!this.required || !isNullOrWhiteSpace(v)) || "Duration is required",
                v => {
                    if (isNullOrWhiteSpace(v) || isValid(v)) {
                        return true;
                    }
                    return "Enter duration in the format h:mm:ss, h:mm, or a number";
                }
            ]
        }
    },

    computed: {
        combinedRules() {
            return [  ...this.defaultRules, ...this.rules.map(mapRule), ];
        },
        valid() {
            return this
                .combinedRules
                .every(rule => rule(this.duration) === true);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value === null) {
                    this.duration = null;
                    return;
                }
                let duration = Duration.fromMillis(value);
                this.duration = duration.toFormat("h:mm");
            }
        }
    },

    methods: {
        setDuration(input) {
            if (isNullOrWhiteSpace(input)) {
                this.$emit("input", null);
                return;
            }

            if (!this.valid) {
                return;
            }

            let durationDisplay = formatDuration(input)
            let duration = parseDuration(durationDisplay);
            let milliseconds = duration.toMillis();
            if (this.value !== milliseconds) {
                this.$emit("input", milliseconds);
            }
            else {
                this.duration = duration.toFormat("h:mm");
            }
        }
    }
};
</script>

<style lang="scss">
    .app-duration-field {
        &.v-text-field--enclosed.v-input--dense.v-text-field--outlined .v-input__append-inner {
            margin: 2px -10px 2px 2px;
        }
    }
</style>
