import { render, staticRenderFns } from "./SchemaItem.vue?vue&type=template&id=57b7edeb&scoped=true"
import script from "./SchemaItem.vue?vue&type=script&lang=js"
export * from "./SchemaItem.vue?vue&type=script&lang=js"
import style0 from "./SchemaItem.vue?vue&type=style&index=0&id=57b7edeb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b7edeb",
  null
  
)

export default component.exports