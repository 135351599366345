export default {
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        addDisabled: {
            type: Boolean,
            default: false
        },
        editDisabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        canAdd() {
            return !this.readonly && !this.$attrs?.disabled && !this.addDisabled;
        },
        canEdit() {
            return !this.readonly && !this.$attrs?.disabled && !this.editDisabled;
        },
        canDelete() {
            return !this.readonly && !this.$attrs?.disabled && !this.editDisabled;
        },
    }
};
