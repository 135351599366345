export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/features/account/views/Login.vue"),
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: () => import("@/features/account/views/ChangePassword.vue"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/features/account/views/ResetPassword.vue"),
    },
    {
        path: "/request-reset-password",
        name: "requestResetPassword",
        component: () => import("@/features/account/views/RequestResetPassword.vue"),
    },
    {
        path: "/sign-up",
        name: "signUp",
        component: () => import("@/features/account/views/SignUp.vue"),
    },
    {
        path: "/two-factor-authentication",
        name: "twoFactorAuthentication",
        component: () => import("@/features/account/views/TwoFactorAuthentication.vue"),
    },
    {
        path: "/accept-invitation",
        name: "acceptInvitation",
        component: () => import("@/features/account/views/AcceptInvitation.vue")
    }
];
