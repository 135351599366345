<template>
    <app-data-table
        :class="{ 'app-data-table-editable': canEdit }"
        :items="tableData.items"
        :headers="headers"
        :server-items-length="tableData.total"
        :list-options.sync="options">
        <template v-for="field in fieldKeys" #[`item.${field}`]="{ item }">
            <div v-if="item[field]?.length > 40" :key="field" class="d-flex">
                <component
                    :is="getCellComponent(field)"
                    :options="getCellOptions(field)"
                    :value="item[field]"
                    :item="item" />
                <v-icon
                    fab
                    color="primary"
                    @click.stop="showDetails = true">
                    mdi-open-in-new
                </v-icon>
                <app-message-dialog
                    v-model="showDetails"
                    :title="camelToTitleCase(field)"
                    @ok="showDetails = false">
                    {{ item[field] }}
                </app-message-dialog>
            </div>
            <component
                :is="getCellComponent(field)"
                v-else
                :key="field + 'single'"
                :options="getCellOptions(field)"
                :value="item[field]"
                :item="item" />
        </template>
        <template #[`item.actions`]="{ item }">
            <div class="no-overflow-ellipsis">
                <v-icon
                    v-if="canEdit"
                    fab
                    color="primary"
                    @click.stop="$emit('edit', item)">
                    mdi-pencil-circle
                </v-icon>
                <v-icon
                    v-if="canDelete"
                    fab
                    color="primary"
                    @click.stop="$emit('delete', item)">
                    mdi-delete
                </v-icon>
                <v-icon
                    v-if="canView"
                    fab
                    color="secondary"
                    @click.stop="viewItem(item)">
                    mdi-arrow-right-circle
                </v-icon>
                <slot name="extra-actions" :item="item" />
            </div>
        </template>
    </app-data-table>
</template>

<script>
import dataTableMixin from "@/features/schemas/mixins/dataTableMixin";
import { camelToTitleCase } from "@/services/stringUtility";

export default {
    mixins: [ dataTableMixin ],
    types: [
        "default"
    ],
    data() {
        return {
            showDetails: false
        };
    },
    methods: {
        camelToTitleCase
    }
}
</script>

<style lang="scss" scoped>
    .app-data-table-editable::v-deep th:last-child {
        width: 1%;
    }

    .app-data-table-editable::v-deep td:last-child {
        white-space: nowrap;
    }
</style>
