<template>
    <div>{{ display }}</div>
</template>

<script>
import { getFormatter } from "@/formatting";

export default {
    types: ["[]"],
    props: {
        value: {
            type: [Array],
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        elementType() {
            const type = this.options?.field?.type;
            if (!type || !type.endsWith("[]")) {
                return null;
            }
            return type.slice(0, -2);
        },
        format() {
            return getFormatter({ type: this.elementType }) ?? ((value) => value);
        },
        display() {
            if (this.value == null || !Array.isArray(this.value)) {
                return "";
            }
            return this.value
                .map((v) => {
                    return this.format(v);
                })
                .join(", ");
        },
    },
};
</script>
