<template>
    <div>
        {{ value ? "Yes" : "No" }}
    </div>
</template>

<script>
export default {
    types: [
        "bool"
    ],
    props: {
        value: {
            type: [ Boolean ],
            default: false,
        }
    }
};
</script>
