export class EntityDependencyProvider {
    #dependenciesByType;

    constructor(context, useDefault = true) {
        const dependenciesByType = {}

        context.keys().forEach(filename => {
            const file = context(filename);

            const dependency = useDefault ?
                file.default :
                file;

            if(!dependency.types) {
                throw `Unable to find the 'types' property in ${filename}.`;
            }

            dependency.types.forEach(type => dependenciesByType[type] = dependency);
        });

        this.#dependenciesByType = dependenciesByType;
    }

    getDependency(schemaField) {
        const type = schemaField.key;
        return (
            this.#dependenciesByType[type] ??
            this.#dependenciesByType["default"]);
    }
}
