<template>
    <div>
        <app-error-dialog v-model="showError" title="Unable to export">
            There is no data to export.
        </app-error-dialog>

        <app-icon-button color="primary" icon="mdi-microsoft-excel" @click="exportToExcel" />
    </div>
</template>

<script>
import { getTableData } from "@/features/schemas/services/tableService";
import { exportToExcel } from "@/features/schemas/services/exporter";
import schemaMixin from "@/features/schemas/mixins/schemaMixin";

export default {
    mixins:[schemaMixin],

    props: {
        filter: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            showError: false
        }
    },
    methods: {
        async exportToExcel() {
            const data = await getTableData(this.entityKey, { filter: this.filter }, true);

            if (data.total === 0) {
                this.showError = true;
                return;
            }

            await exportToExcel(data);
        }
    }
}
</script>
