<template>
    <app-data-table
        :class="{ 'app-data-table-editable': canEdit }"
        :items="itemsWithTags"
        :headers="headers"
        :server-items-length="tableData.total"
        :list-options.sync="options">
        <template v-for="tagCategory in tagCategories" #[`item.${tagCategory.value}`]="{ item }">
            <div
                :key="tagCategory.id"
                class="clickable"
                @click.stop="viewItem(item)">
                {{ item[tagCategory.value] }}
            </div>
        </template>
        <template v-for="field in fieldKeys" #[`item.${field}`]="{ item }">
            <div
                :key="field"
                class="clickable"
                @click.stop="viewItem(item)">
                <component
                    :is="getCellComponent(field)"
                    :options="getCellOptions(field)"
                    :value="item[field]"
                    :item="item" />
            </div>
        </template>
        <template #[`item.actions`]="{ item }">
            <div class="no-overflow-ellipsis">
                <v-btn
                    color="primary"
                    x-small
                    class="mr-1"
                    @click.stop="duplicateReview(item.id)">
                    Duplicate
                </v-btn>
                <v-icon
                    v-if="canEdit"
                    fab
                    color="primary"
                    @click.stop="deleteItem(item)">
                    mdi-delete
                </v-icon>
                <v-icon
                    v-if="canView"
                    fab
                    color="secondary"
                    @click.stop="viewItem(item)">
                    mdi-arrow-right-circle
                </v-icon>
                <slot name="extra-actions" :item="item" />
            </div>
        </template>
    </app-data-table>
</template>

<script>
import dataTableMixin from "@/features/schemas/mixins/dataTableMixin";
import apiService from "@/features/schemas/services/schemaApi";
import { mapGetters } from "vuex"
import { naturalCompare } from "@/services/stringUtility";

export default {
    mixins: [ dataTableMixin ],
    types: [
        "dailyReview"
    ],
    computed: {
        tagCategories() {
            let tagCategoriesSet = new Set();

            return this.tableData
                .items
                .map(e => e.tags)
                .flat()
                .filter(e => {
                    const hasValue = tagCategoriesSet.has(e.tagCategory.name);
                    tagCategoriesSet.add(e.tagCategory.name);
                    return !hasValue;
                })
                .map(e => { return { text: e.tagCategory.name, value: e.tagCategory.name }});
        },
        headers() {
            let headers = [
                ...this.tagCategories,
                ...this.entityHeaders
            ]
            return headers.concat({
                text: "",
                value: "actions",
                sortable: false,
                class: "action-cell"
            });
        },
        ...mapGetters({
            personId: "authentication/personId"
        }),
        localFixedValues() {
            return { personId: this.personId };
        },
        itemsWithTags() {
            return this.tableData.items.map(item => {
                let tagCategoryMapping = {};

                item.tags.forEach(tag => {
                    let categoryName = tag.tagCategory.name;

                    if (!tagCategoryMapping[categoryName]) {
                        tagCategoryMapping[categoryName] = new Set();
                    }

                    tagCategoryMapping[categoryName].add(tag.name);
                });

                for (let [categoryName, tags] of Object.entries(tagCategoryMapping)) {
                    const sortedTags = [...tags].sort(naturalCompare);
                    item[categoryName] = sortedTags.join(', ');
                }

                return item;
            });
        }
    },
    methods: {
        async deleteItem(item) {
            if (!confirm("Are you sure you want to delete this daily review?")) {
                return;
            }
            await apiService.delete(this.entityKey, item.id);
            await this.refresh();
        },
        viewItem(item) {
            this.$router.push("/daily-review-detail/" + item.id)
        },
        duplicateReview(id) {
            this.$router.push('/create/' + id)
        },
    }
}
</script>

<style lang="scss" scoped>
    .app-data-table-editable::v-deep th:last-child {
        width: 1%;
    }

    .app-data-table-editable::v-deep td:last-child {
        white-space: nowrap;
    }
</style>
