<template>
    <div class="d-flex flex-column schema-table">
        <div v-if="!tableOnly" class="d-flex align-center mb-5">
            <v-text-field
                v-model="searchText"
                class="no-print"
                label="Search"
                hide-details
                dense
                color="primary"
                outlined
                prepend-inner-icon="mdi-magnify" />

            <slot name="add-button">
                <app-icon-button
                    v-if="canAdd"
                    class="ml-4 no-print"
                    color="primary"
                    icon="mdi-plus"
                    @click="add" />
                <app-button
                    v-if="canAdd"
                    class="ml-4 no-print"
                    color="primary"
                    @click="onboard">
                    Onboard
                </app-button>
            </slot>
            <schema-excel-export-button
                v-if="!disableExport"
                v-bind="$attrs"
                class="ml-4 no-print"
                :entity-key="entityKey" />
        </div>

        <v-card :class="cardClasses" :elevation="outlined ? 0 : 4">
            <component
                :is="tableComponent"
                :key="entityKey"
                ref="schemaDataTable"
                class="flex-grow-1"
                v-bind="$attrs"
                :entity-key="entityKey"
                :search-text="searchText"
                :readonly="readonly"
                :has-item-page="hasItemPage"
                @edit="edit"
                @delete="deleteItem">
                <template #extra-actions="{ item }">
                    <v-tooltip v-if="item.paymentType == 'PurchaseOrder'" bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon :loading="loading">
                                <v-icon
                                    fab
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click.stop="addYear(item)">
                                    mdi-calendar
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Add Year to Subscription</span>
                    </v-tooltip>
                    <slot name="extra-actions" :item="item" />
                </template>
            </component>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem"
            :form="form"
            v-bind="$attrs"
            @input="onItemSaved" />

        <onboard-school-dialog
            :value="showOnboardSchoolDialog"
            v-bind="$attrs"
            @input="onOnboardComplete" />
        <app-message-dialog
            v-model="showYearDialog"
            title="Year Added"
            @ok="showYearDialog = false">
            <p>
                A year was added to the subscription
            </p>
        </app-message-dialog>
    </div>
</template>

<script>
import tableMixin from "@/features/schemas/mixins/tableMixin";
import OnboardSchoolDialog from '@/features/schools/dialogs/OnboardSchoolDialog.vue';
import { update, get } from "@/services/apiService";
import { addYears } from "@/services/dateUtility"

export default {
    components: { OnboardSchoolDialog },
    mixins:[tableMixin],
    types: [
        "school"
    ],
    data() {
        return {
            showOnboardSchoolDialog: false,
            showYearDialog: false,
            loading: false
        }
    },
    methods: {
        onboard() {
            this.showOnboardSchoolDialog = true;
        },
        async onOnboardComplete() {
            this.showOnboardSchoolDialog = false;
            await this.refresh();
        },
        async addYear(item) {
            this.loading = true;
            try {
                let subscription = await get("subscriptions", item.subscriptionId);
                subscription.endAt = addYears(subscription.endAt, 1);
                await update("subscriptions", subscription),
                this.showYearDialog = true;
                await this.refresh();
            }
            finally {
                this.loading = false;
            }
        }
    }

};
</script>
