import { camelToKebabCase } from '@/services/stringUtility'

const defaultComponent = () =>
    import(/* webpackChunkName: "schemaItemView" */ "@/features/schemas/views/SchemaItemView.vue");

// Define custom components here
const customComponents = {
    topic: () =>
        import("@/features/contentManagement/ManageTopic.vue"),
};

// Define custom static properties here
const customStaticProps = { };

const entities = [
    "dailyReview",
    "dailyReviewVariant",
    "feedback",
    "file",
    "format",
    "freeAccessDomain",
    "person",
    "school",
    "schoolTeacher",
    "setting",
    "slide",
    "subscription",
    "tag",
    "tagCategory",
    "timeZone",
    "topic",
    "topicTag",
    "user",
    "userRole",
    "variant"
];

export function hasItemPage(entityKey) {
    return entities.includes(entityKey) || Object.keys(customComponents).includes(entityKey);
}

// Generate route with given entity and custom component if exists
const generateRoute = (e) => ({
    path: `/${camelToKebabCase(e)}/:itemId`,
    name: e,
    staticProps: { entityKey: e, ...customStaticProps[e] },
    component: customComponents[e] || defaultComponent
});

const keys = [...Object.keys(customComponents), ...entities];
const uniqueKeys = [...new Set(keys)];

// Create routes for both custom and default entities
const routes = uniqueKeys.map(generateRoute);

export default routes;
