export const types = [
    "bool"
];

export function format(value) {
    switch(value) {
        case true: return "Yes";
        case false: return "No";
        case null: return "";
        default: throw `${value} is not a valid boolean value`;
    }
}