function distinct(items){
    return [...new Set(items)];
}

function distinctBy(items, getField) {
    let seen = new Set();
    let result = [];
    for (let i = 0; i < items.length; i++) {
        let field = getField(items[i]);
        if(seen.has(field))
        {
            continue;
        }
        seen.add(field);
        result.push(items[i]);
    }
    return result;
}

function groupBy(items, getKey) {
    return items.reduce((accumulator, item) => {
        const key = getKey(item);
        (accumulator[key] = accumulator[key] || []).push(item);
        return accumulator;
    }, {});
}

function byId(items) {
    return Object.assign({}, ...items.map(i => ({ [i.id]: i })));
}

function lookup(items, keySelector, itemSelector)
{
    if (itemSelector == null) {
        itemSelector = item => item;
    }

    let lookup = {};
    items.forEach(item => (lookup[keySelector(item)] ??= []).push(itemSelector(item)));
    return lookup;
}

function move(array, fromIndex, toIndex) {
    if(fromIndex < 0 || toIndex < 0 || fromIndex >= array.length || toIndex >= array.length) {
        throw 'index out of bounds'
    }

    if(toIndex === fromIndex) {
        return;
    }

    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
}

function remove(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
}

export default { distinct, distinctBy, byId, lookup, move, groupBy, remove };
export { distinct, distinctBy, byId, lookup, move, groupBy, remove };
