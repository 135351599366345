import filtering from "@/services/filtering";
import { getType } from "./schemaProvider";
export { and, or, any, all } from "@/services/filtering";

export function equal(entityKey, fieldKey, value) {
    const type = getType(entityKey, fieldKey);
    return filtering.equal(fieldKey, value, type);
}

export function notEqual(entityKey, fieldKey, value) {
    const type = getType(entityKey, fieldKey);
    return filtering.notEqual(fieldKey, value, type);
}

export function lessThan(entityKey, fieldKey, value) {
    const type = getType(entityKey, fieldKey);
    return filtering.lessThan(fieldKey, value, type);
}

export function lessThanOrEqual(entityKey, fieldKey, value) {
    const type = getType(entityKey, fieldKey);
    return filtering.lessThanOrEqual(fieldKey, value, type);
}

export function greaterThan(entityKey, fieldKey, value){
    const type = getType(entityKey, fieldKey);
    return filtering.greaterThan(fieldKey, value, type);
}

export function greaterThanOrEqual(entityKey, fieldKey, value){
    const type = getType(entityKey, fieldKey);
    return filtering.greaterThanOrEqual(fieldKey, value, type);
}

export function startsWith(entityKey, fieldKey, value){
    const type = getType(entityKey, fieldKey);
    return filtering.startsWith(fieldKey, value, type);
}

export function anyValue(entityKey, fieldKey, values) {
    const type = getType(entityKey, fieldKey);
    return filtering.anyValue(fieldKey, values, type);
}

export default { 
    and: filtering.and, 
    or: filtering.or, 
    equal, 
    notEqual, 
    lessThan, 
    lessThanOrEqual, 
    greaterThan, 
    greaterThanOrEqual, 
    startsWith, 
    any: filtering.any, 
    all: filtering.all,
    anyValue
}