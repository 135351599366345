import { FieldDependencyProvider } from "@/features/schemas/services/fieldDependencyProvider";

// Grab all the *Formatter.js files in this folder (exluding subfolders).
const context = require.context(".", false, /.*Formatter\.js$/);
const provider = new FieldDependencyProvider(context, false);

export function getFormatter(schemaField) {
    const service = provider.getDependency(schemaField);

    if (service.types.includes("entity")) {
        return (value) => service?.format(schemaField.type, value);
    }

    return service?.format;
}

export default {
    getFormatter,
};
