import { getEnum } from "./schemaProvider";
import { getEntity } from "./schemaProvider";
import { hasProperty } from "@/services/objectUtility";
import { isNullOrWhiteSpace, pascalToCamelCase } from "@/services/stringUtility";

export class FieldDependencyProvider {
    #dependenciesByType;

    constructor(context, useDefault = true) {
        const dependenciesByType = {};

        context.keys().forEach((filename) => {
            const file = context(filename);

            const dependency = useDefault ?
                file.default :
                file;

            if (!dependency.types) {
                throw `Unable to find the 'types' property in ${filename}.`;
            }

            dependency.types.forEach((type) => dependenciesByType[type] = dependency);
        });

        this.#dependenciesByType = dependenciesByType;
    }

    getDependency(schemaField) {
        // Entity fields are integers. We want to resolve these as the "entity" type.
        const type = this.getSchemaFieldDependencyType(schemaField);
        return (
            this.#dependenciesByType[type] ??
            this.#dependenciesByType["default"]);
    }

    getSchemaFieldDependencyType(schemaField) {
        // If we're looking at a foreign key, then look for a dependency which handles that
        // specific type, otherwise fall back to the generic type.
        if ("dependsOn" in schemaField) {
            let entityType = schemaField.dependsOn.entityKey;

            if (this.hasEntityDependency(entityType)) {
                return entityType;
            }

            return "entity";
        }

        if ("subtype" in schemaField) {
            let subtype = pascalToCamelCase(schemaField.subtype.name);
            if (!isNullOrWhiteSpace(subtype) && this.hasEntityDependency(subtype)) {
                return subtype;
            }
        }

        // Remove trailing "?" from nullable types.
        let type = schemaField.type.replace(/\?$/, "");

        // If we have a specific item for this type, then return it.
        if (this.hasEntityDependency(type)) {
            return type;
        }

        if (type.endsWith("[]")) {
            return "[]";
        }

        // This is a foreign key, but we have the lookup in our schema already.
        // TODO: Issue if type of Entity is same name as an Enum.
        if (getEntity(type) !== null) {
            return "entity";
        }

        if (getEnum(type) !== null) {
            return "enum";
        }

        return type;
    }

    getDependencyType(type) {
        // Remove trailing "?" from nullable types.
        type = type.replace(/\?$/, "");

        // If we have a specific item for this type, then return it.
        if (this.hasEntityDependency(type)) {
            return type;
        }

        if (type.endsWith("[]")) {
            return "[]";
        }

        // This is a foreign key, but we have the lookup in our schema already.
        // TODO: Issue if type of Entity is same name as an Enum.
        if (getEntity(type) !== null) {
            return "entity";
        }

        if (getEnum(type) !== null) {
            return "enum";
        }

        return type;
    }

    hasEntityDependency(entityType) {
        return hasProperty(this.#dependenciesByType, entityType);
    }
}
