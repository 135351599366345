import axios from "axios";

function getDefaultState() {
    return {
        settings: {},
        isLoaded: false
    }
}

const state = getDefaultState;

const getters = {
    settings: state => state.settings,
}

const actions = {
    async load({ commit }) {
        let settings = (await axios.get("/api/StripeSubscriptions/Settings")).data;
        commit("settings", settings);
    },
    clear({ commit }) {
        commit("clear");
    }
};

const mutations = {
    settings(state, settings) {
        state.settings = settings;
        state.isLoaded = true;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
}

export const stripe = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
