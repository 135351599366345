<template>
    <v-btn
        v-if="value"
        class="no-overflow-ellipsis"
        dark
        small
        label
        :color="chipColor"
        @click="onClick">
        {{ displayValue }}
    </v-btn>
</template>

<script>
import axios from "axios";

export default {
    types: [
        "teacherStatus",
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
    },
    computed: {
        chipColor() {
            switch (this.value) {
                case "Active":
                    return "green";
                case "InviteSent":
                    return "blue";
                default:
                    return "grey";
            }
        },
        displayValue() {
            switch (this.value) {
                case "Active":
                    return "Active";
                case "InviteSent":
                    return "Resend Invite";
                default:
                    return "Error";
            }
        }
    },
    methods: {
        async onClick() {
            if (this.value == "InviteSent" && confirm("Do you want to resend this invitation?")) {
                await axios.post("/api/SchoolTeachers/ResendInvitation", { id: this.item.id });
                this.$store.dispatch("alert/success","Invitation resent");
            }
        }
    }
};
</script>
