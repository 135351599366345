import Vue from "vue";
import Router from "vue-router";
import accountRoutes from "./accountRoutes";
import tableRoutes from "./tableRoutes";
import itemRoutes from "./itemRoutes";
import reportRoutes from "./reportRoutes";
import { parseQuery, stringifyQuery } from "@/services/queryStringService"
import store from '@/store/store';
import { routeIsPublic } from "@/services/routeService";

Vue.use(Router);

let routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/features/dashboard/Index.vue"),
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("@/features/admin/Index.vue"),
    },
    {
        path: "/reports",
        name: "reports",
        component: () =>
            import("@/features/reports/views/Reports.vue"),
    },
    {
        path: "/create",
        name: "createDailyReview",
        component: () =>
            import("@/features/dailyReviewCreation/Create.vue"),
    },
    {
        path: "/create/:itemId",
        name: "duplicateDailyReview",
        component: () =>
            import("@/features/dailyReviewCreation/Create.vue"),
    },
    {
        path: "/daily-review-detail/:id",
        name: "dailyReviewDetail",
        component: () =>
            import("@/features/dailyReviewCreation/DailyReviewDetail.vue"),
    },
    {
        path: "/topics",
        name: "topics",
        component: () =>
            import("@/features/contentManagement/ManageTopics.vue"),
        staticProps: {
            exportDisabled: true
        },
    },
    {
        path: "/daily-reviews",
        name: "dailyReviews",
        component: () =>
            import(
                /* webpackChunkName: "schemaTableView" */
                "@/features/schemas/views/SchemaTableView.vue"),
        staticProps: { entityKey: "dailyReview", exportDisabled: true },
    },
    {
        path: "/suggestions-and-feedback",
        name: "suggestionsAndFeedback",
        component: () =>
            import("@/features/contentManagement/ContentSuggestions.vue"),
    },
    {
        path: "/subscription-management",
        name: "subscriptionManagement",
        component: () =>
            import("@/features/subscriptionManagement/views/SelectSubscription.vue"),
    },
    {
        path: "/payment/full-access",
        name: "paymentFullAccess",
        component: () =>
            import("@/features/subscriptionManagement/views/FullAccessPayment.vue"),
    },
    {
        path: "/payment/school",
        name: "paymentSchool",
        component: () =>
            import("@/features/subscriptionManagement/views/SchoolPayment.vue"),
    },
    {
        path: "/cancel-subscription",
        name: "cancelSubscription",
        component: () =>
            import("@/features/subscriptionManagement/views/CancelSubscription.vue"),
    },
    {
        path: "/resume-subscription",
        name: "resumeSubscription",
        component: () =>
            import("@/features/subscriptionManagement/views/ResumeSubscription.vue"),
    },
    {
        path: "/manage-subscription/full-access",
        name: "manageSubscriptionFullAccess",
        component: () =>
            import("@/features/subscriptionManagement/views/ManageSubscriptionFullAccess.vue"),
    },
    {
        path: "/variant-import",
        name: "variantImport",
        component: () =>
            import("@/features/import/VariantImport.vue"),
    },
    {
        path: "/manage-subscription/school/change-credit-card",
        name: "manageSubscriptionSchoolChangeCreditCard",
        component: () =>
            import(
                // eslint-disable-next-line
                "@/features/subscriptionManagement/views/ManageSubscriptionSchoolChangeCreditCard.vue"
            ),
    },
    {
        path: "/manage-subscription/school/add-remove-teachers",
        name: "ManageSubscriptionSchoolChangeTeacherCount",
        component: () =>
            import(
                // eslint-disable-next-line
                "@/features/subscriptionManagement/views/ManageSubscriptionSchoolChangeTeacherCount.vue"
            ),
    },
    {
        path: "/terms-and-conditions",
        name: "termsAndConditions",
        component: () =>
            import("@/features/account/views/TermsAndConditions.vue"),
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () =>
            import("@/features/account/views/PrivacyPolicy.vue"),
    },
    {
        path: "*",
        redirect: "/",
    },
];

routes = routes
    .concat(accountRoutes)
    .concat(tableRoutes)
    .concat(itemRoutes)
    .concat(reportRoutes);

// Pass the query string and route parameters as props into all routes.
routes.forEach(addProps);

function addProps(r) {
    r.props = route => ({
        ...route.params,
        ...route.query,
        ...r.staticProps
    });

    r.children?.forEach(addProps);
}

const router = new Router({
    mode: "history",
    parseQuery,
    stringifyQuery,
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    let isPublicRoute = routeIsPublic(to);
    let isPublicOrAuthenticationRoute = isPublicRoute || to.path === "/two-factor-authentication";
    await store.dispatch('authentication/waitForLoad');
    let isFullyAuthenticated = store.state.authentication.user?.isFullyAuthenticated;
    let isLoggedIn = store.state.authentication.isLoggedIn;
    // if on a public or authentication route and fully authenticated, go to home
    if(isPublicOrAuthenticationRoute && isFullyAuthenticated) {
        redirect('/', to.path, next);
        return;
    }
    // if not on a public or authentication route and not fully authenticated,
    // go to two factor authentication
    if (!isPublicOrAuthenticationRoute && !isFullyAuthenticated && isLoggedIn) {
        redirect('/two-factor-authentication', to.path, next);
        return;
    }
    // if not on a public route and not logged in, go to login
    if (!isPublicRoute && !isLoggedIn) {
        redirect('/login', to.path, next);
        return;
    }
    next();
});

function redirect (to, currentTo, next) {
    if (currentTo === to) {
        next()
        return;
    }
    next({ path: to })
}

export default router;
