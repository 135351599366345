export function isEmpty(value) {
    let type = typeof value;

    return (
        value === null ||
        type === "undefined" ||
        value === "" ||
        (Array.isArray(value) && !value.length) ||
        (type === "object" && Object.keys(value).length === 0));
}

export function hasProperty(obj, propertyName) {
    if(obj == null) {
        return false;
    }

    return Object
        .prototype
        .hasOwnProperty
        .call(obj, propertyName)
}
