import { EntityDependencyProvider } from "@/features/schemas/services/entityDependencyProvider";

// Grab all the vue files in this folder (exluding subfolders).
const context = require.context('.', false, /.*\.vue$/);
const provider = new EntityDependencyProvider(context);

export function getComponent(schemaField) {
    return provider.getDependency(schemaField);
}

export default {
    getComponent
}
