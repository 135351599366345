<template>
    <div class="d-flex">
        <schema-search-select
            v-if="field && field.dependsOn"
            class="flex-grow-1"
            v-bind="$attrs"
            :value="value"
            :label="field.title"
            :rules="rules"
            :entity-key="field.dependsOn.entityKey"
            search-when-blank
            v-on="$listeners" />

        <app-icon-button
            v-if="canAdd"
            class="ml-2"
            icon="mdi-plus"
            @click="add" />

        <schema-form-dialog
            :entity-key="field.dependsOn.entityKey"
            :value="editedItem"
            @input="onItemSaved" />
    </div>
</template>

<script>
import formFieldMixin from "./formFieldMixin";
import { canAdd } from "@/features/schemas/services/schemaApi";

export default {
    types: ["entity"],
    mixins: [formFieldMixin],
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            editedItem: null
        };
    },
    computed: {
        canAdd() {
            return canAdd(this.field.dependsOn.entityKey);
        }
    },
    methods: {
        add() {
            this.editedItem = {};
        },
        async onItemSaved(value) {
            this.editedItem = null;
            if (value) {
                this.$emit('input', value.id)
            }
        },
    }
}
</script>
