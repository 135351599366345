import tableRoutes from "@/routes/tableRoutes";

export function getTableRoute(entityKey) {
    const matchingRoutes = tableRoutes
        .filter(r => r.staticProps?.entityKey == entityKey);

    return matchingRoutes.length ?
        matchingRoutes[0].path :
        null;
}
