import axios from "axios";

export default {
    login,
    logout,
    changePassword,
    resetPassword,
    requestPasswordReset,
    currentUser,
    acceptInvitation
};

export async function login(emailAddress, password) {
    return await axios.post("/api/Account/Login", { emailAddress, password });
}

export async function logout() {
    await axios.post("/api/Account/Logout");
}

export async function changePassword(oldPassword, newPassword) {
    await axios.post("api/Account/ChangePassword", { oldPassword, newPassword });
}

export async function resetPassword(token, newPassword) {
    await axios.post("api/Account/ResetPassword", { token, newPassword });
}

export async function requestPasswordReset(emailAddress) {
    await axios.post("api/Account/RequestPasswordReset", { emailAddress });
}

export async function currentUser() {
    const response = await axios.get("/api/Account/CurrentUser");
    const user = response.data;
    return !user ? null : user;
}

export async function acceptInvitation(token, newPassword, personName) {
    await axios.post("api/Account/AcceptInvitation", { token, newPassword, personName });
}
